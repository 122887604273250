import dayjs from 'dayjs';

export const sportBetTypeOption = [
  {
    value: 'ORDINAR',
    key: 0,
  },
  {
    value: 'EXPRESS',
    key: 1,
  },
  {
    value: 'SYSTEM',
    key: 2,
  },
];
export const sportStatusOption = [
  {
    value: 'Pending',
    key: 0,
  },
  {
    value: 'Won',
    key: 2,
  },
  {
    value: 'Lost',
    key: 3,
  },
];

export const bettingOption = [
  {
    label: 'Real Money',
    value: 'MONEY',
  },
  {
    label: 'Bonus',
    value: 'BONUS',
  },
];

export const sportSmallTotalTableColumn = [
  { title: 'Total Bet', dataIndex: 'sport_total_bet', key: 'sport_total_bet' },
  { title: 'Total Win', dataIndex: 'sport_total_won', key: 'sport_total_won' },
  { title: 'Total GGR', dataIndex: 'ggr', key: 'ggr' },
];
export const sportSmallByDateTableColumn = [
  { title: 'Bet', dataIndex: 'sport_total_bet', key: 'sport_total_bet' },
  { title: 'Win', dataIndex: 'sport_total_won', key: 'sport_total_won' },
  { title: 'GGR', dataIndex: 'ggr', key: 'ggr' },
];

// ztype
const enum SportBetType {
  ORDINAR = 0,
  MULTY = 1,
  SYSTEM = 2,
}

// status
export const enum SportBetStatus {
  Pending = 0,
  Return = 1,
  Won = 2,
  Lost = 3,
}

// Map enums to readable strings
const sportBetTypeMap = {
  [SportBetType.ORDINAR]: 'ORDINAR',
  [SportBetType.MULTY]: 'EXPRESS',
  [SportBetType.SYSTEM]: 'SYSTEM',
};

export const sportBetStatusMap = {
  [SportBetStatus.Pending]: 'Pending',
  [SportBetStatus.Return]: 'Return',
  [SportBetStatus.Won]: 'Won',
  [SportBetStatus.Lost]: 'Lost',
};

export const sportTabGeneralTableColumn = [
  {
    title: 'Ticket ID',
    dataIndex: 'package_id',
    key: 'package_id',
  },
  {
    title: 'Bet Amount',
    dataIndex: 'bet_amount',
    key: 'bet_amount',
  },
  {
    title: 'Bet Type',
    dataIndex: 'ztype',
    key: 'ztype',
    render: (ztype: SportBetType) => sportBetTypeMap[ztype],
  },
  {
    title: 'EXTRA',
    dataIndex: 'money_option',
    key: 'money_option',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: SportBetStatus) => sportBetStatusMap[status],
  },
  {
    title: 'Payout',
    dataIndex: 'payout_amount',
    key: 'payout_amount',
  },
  {
    title: 'Created Date',
    dataIndex: 'date_created',
    key: 'date_created',
    render: (date_created: string | null) =>
      date_created ? (
        dayjs(date_created).utc().format('YYYY-MM-DD HH:mm')
      ) : (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}>
          -
        </div>
      ),
  },
  {
    title: 'Calculated Date',
    dataIndex: 'date_calculated',
    key: 'date_calculated',
    render: (date_calculated: string | null) =>
      date_calculated ? (
        dayjs(date_calculated).utc().format('YYYY-MM-DD HH:mm')
      ) : (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}>
          -
        </div>
      ),
  },
];
